import { createContext } from '@dwarvesf/react-utils'
import { userService } from 'api'
import { REACTION } from 'constants/enum'
import { useAuthContext } from 'context/auth'
import { useFetchFeeds } from 'hooks/feed/useFetchFeeds'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { WithChildren } from 'types/common'
import { GetNewFeedResponseData } from 'types/schema'

interface NewFeedContextValues {
  newFeeds?: GetNewFeedResponseData[]
  isEmpty: boolean
  sentryRef: any
  hasNextPage: boolean
  isFirstLoading: boolean
  loading: boolean
  selectedFeed?: GetNewFeedResponseData
  isLoadingMore: boolean
  setNewFeed: (newFeed: GetNewFeedResponseData) => void
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onLikeFeed: (id?: string) => void
  onCommentFeed: (id: string, comment: string) => void
}

const [Provider, useNewFeedListContext] = createContext<NewFeedContextValues>({
  name: 'new-feed-list',
})

const NewFeedListProvider = ({ children }: WithChildren) => {
  const {
    newFeeds,
    isEmpty,
    hasNextPage,
    isFirstLoading,
    loading,
    error,
    isLoadingMore,
    handleLoadMore,
    setNewFeeds,
  } = useFetchFeeds()

  const [sentryRef] = useInfiniteScroll({
    loading: isFirstLoading || loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const { user } = useAuthContext()

  const [selectedFeed, setSelectedFeed] = useState<GetNewFeedResponseData>()
  const [isOpen, setIsOpen] = useState(false)

  const setNewFeed = (newFeed: GetNewFeedResponseData) => {
    setSelectedFeed(newFeed)
  }

  const onLikeFeed = async (id?: string) => {
    try {
      const newData = newFeeds?.map((item) => {
        if (item?.id === id) {
          const newItem = {
            ...item,
            isLike: !item?.isLike,
            totalReaction: !item?.isLike
              ? item.totalReaction + 1
              : item.totalReaction - 1,
          }
          return newItem
        }

        return item
      })
      setNewFeeds(newData)

      await userService.likeNewFeed({
        id,
        reaction: REACTION.LIKE,
      })
    } catch (error: any) {
      // update state is liked before call api so we must update state to previous data when it's error
      setNewFeeds(newFeeds)
      console.error(error)
    }
  }

  const onCommentFeed = async (id: string, comment: string) => {
    try {
      const { data } = await userService.commentNewFeed({
        id,
        content: comment,
      })

      const newData = newFeeds?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            replies: [
              {
                id: data?.commentId,
                avatar: user?.avatar || '',
                userId: user?.id || '',
                content: comment,
                isLike: false,
                isPin: false,
                createdAt: data?.createdAt,
                totalReaction: 0,
                fullName: user?.personalInfo?.fullName || '',
              },
              ...(item?.replies ?? []),
            ],
          }
        }

        return item
      })
      setNewFeeds(newData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const item = newFeeds?.find((item) => item.id === selectedFeed?.id)
    if (item) {
      setSelectedFeed(item)
    }
  }, [newFeeds, selectedFeed])

  return (
    <Provider
      value={{
        newFeeds,
        isEmpty,
        sentryRef,
        hasNextPage,
        isFirstLoading,
        loading,
        selectedFeed,
        isOpen,
        isLoadingMore,
        setIsOpen,
        setNewFeed,
        onLikeFeed,
        onCommentFeed,
      }}
    >
      {children}
    </Provider>
  )
}

export { NewFeedListProvider, useNewFeedListContext }
