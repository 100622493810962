import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import cx from 'classnames'
import { noop } from '@dwarvesf/react-utils'
import { IS_IOS_LESS_THAN_13 } from 'constants/mobile'
import { DrawerAnchor, DrawerContextProvider } from './context'
import { DrawerCloseButton } from './DrawerCloseButton'
import { DrawerContent } from './DrawerContent'
import { DrawerTitle } from './DrawerTitle'
import { DrawerHeader } from './DrawerHeader'
import { DrawerFooter } from './DrawerFooter'

export interface DrawerProps {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  className?: string
  isAnimated?: boolean
  anchor?: DrawerAnchor
  fullScreen?: boolean
}

export function Drawer(props: DrawerProps) {
  const {
    isOpen,
    children,
    onClose,
    className,
    isAnimated = true,
    anchor = 'left',
    fullScreen = true,
  } = props

  let animationProps = {}

  if (isAnimated) {
    if (anchor === 'left') {
      animationProps = {
        enter: 'ease-out duration-300',
        enterFrom: '-translate-x-full',
        enterTo: 'translate-x-0',
        leave: 'ease-in duration-200',
        leaveFrom: 'translate-x-0',
        leaveTo: '-translate-x-full',
      }
    } else if (anchor === 'right') {
      animationProps = {
        enter: 'ease-out duration-300',
        enterFrom: 'translate-x-full',
        enterTo: 'translate-x-0',
        leave: 'ease-in duration-200',
        leaveFrom: 'translate-x-0',
        leaveTo: 'translate-x-full',
      }
    } else if (anchor === 'top') {
      animationProps = {
        enter: 'ease-out duration-300',
        enterFrom: '-translate-y-full',
        enterTo: 'translate-y-0',
        leave: 'ease-in duration-200',
        leaveFrom: 'translate-y-0',
        leaveTo: '-translate-y-full',
      }
    } else {
      animationProps = {
        enter: 'ease-out duration-300',
        enterFrom: 'translate-y-full',
        enterTo: 'translate-y-0',
        leave: 'ease-in duration-200',
        leaveFrom: 'translate-y-0',
        leaveTo: 'translate-y-full',
      }
    }
  }

  return (
    <DrawerContextProvider value={{ isOpen, onClose, anchor }}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={noop}
          initialFocus={undefined}
          open={IS_IOS_LESS_THAN_13 ? isOpen : undefined}
        >
          <div className="sm:relative sm:container sm:h-full sm:transform sm:translate-x-0">
            {!fullScreen ? (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay
                  className="fixed inset-0 bg-black bg-opacity-80"
                  onClick={onClose}
                />
              </Transition.Child>
            ) : null}

            <Transition.Child as={Fragment} {...animationProps}>
              <div
                className={cx(
                  'bg-white absolute',
                  { 'left-0 inset-y-0': anchor === 'left' },
                  { 'right-0 inset-y-0': anchor === 'right' },
                  { 'inset-x-0 top-0': anchor === 'top' },
                  { 'inset-x-0 bottom-0': anchor === 'bottom' },
                  {
                    'w-full':
                      fullScreen && (anchor === 'left' || anchor === 'right'),
                  },
                  {
                    'h-full':
                      fullScreen && (anchor === 'top' || anchor === 'bottom'),
                  },
                  className,
                )}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </DrawerContextProvider>
  )
}

export {
  DrawerCloseButton,
  DrawerTitle,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
}
