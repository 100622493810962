import { callAllHandlers } from '@dwarvesf/react-utils'
import cx from 'classnames'
import { IconArrowLeft } from 'components/icons/components/IconArrowLeft'
import { useTranslations } from 'use-intl'
import { IconButton, IconButtonProps } from '../IconButton'
import { useBottomSheetContext } from './context'

interface Props extends Omit<IconButtonProps, 'label' | 'Icon'> {}

export const BottomSheetBackButton = (props: Props) => {
  const { className, disabled, onClick, ...rest } = props
  const { onClose } = useBottomSheetContext()
  const t = useTranslations()

  return (
    <IconButton
      disabled={disabled}
      onClick={callAllHandlers(onClose, onClick)}
      {...rest}
      className={cx(
        className,
        '!text-2xl !absolute left-4 center-y !text-v2-gray-400',
      )}
      variant="link"
      scheme="primary"
      Icon={IconArrowLeft}
      size="sm"
      label={t('close')}
    />
  )
}
