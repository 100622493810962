import React from 'react'
import cx from 'classnames'
import { Text } from 'components/ui/Text'
import { IconArrowRight } from 'components/icons/components/IconArrowRight'
import { forwardRefWithAs } from 'utils/render'
import { ColorCode } from 'utils/ui'

interface RowItemProps {
  size?: 'sm' | 'md'
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  rightExtra?: React.ReactNode
  className?: string
  titleTextColor?: ColorCode
  titleClassName?: string
  rightExtraClassName?: string
  leftIconClassName?: string
  rightIconClassName?: string
}

export const RowItem = forwardRefWithAs<'button', RowItemProps>(
  (props, ref) => {
    const {
      as: Tag = 'button',
      leftIcon,
      rightIcon,
      className,
      title,
      subtitle,
      size = 'md',
      rightExtra,
      titleTextColor,
      titleClassName,
      rightExtraClassName,
      leftIconClassName,
      rightIconClassName,
      ...rest
    } = props

    return (
      <Tag
        ref={ref}
        className={cx(
          'w-full flex items-center space-x-4',
          { 'h-[46px]': size === 'sm' },
          { 'h-[65px]': size === 'md' },
          className,
        )}
        {...rest}
      >
        {leftIcon ? (
          <span
            className={cx(
              'flex items-center justify-center w-10 h-10 text-xl',
              leftIconClassName,
            )}
          >
            {leftIcon}
          </span>
        ) : null}
        <div className="text-left grow">
          <Text
            className={cx('font-medium', titleClassName)}
            v2TextColor={titleTextColor || 'Gray-Dark'}
          >
            {title}
          </Text>
          {subtitle}
        </div>
        <div className={cx('flex items-center space-x-3', rightExtraClassName)}>
          {rightExtra}{' '}
          {rightIcon ?? (
            <IconArrowRight className={cx('text-xl', rightIconClassName)} />
          )}
        </div>
      </Tag>
    )
  },
)
