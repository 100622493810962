import cx from 'classnames'
import { HTMLAttributes } from 'react'
import { WithChildren } from 'types/common'

interface Props extends HTMLAttributes<HTMLDivElement> {
  hasHeight?: boolean
}

export const DrawerHeader = (props: WithChildren<Props>) => {
  const { className, children, hasHeight = true, ...rest } = props
  return (
    <>
      <header
        {...rest}
        className={cx(
          'bg-white px-12 flex items-center z-20 shadow-300 absolute top-0 inset-x-0 h-[72px]',
          className,
        )}
      >
        {children}
      </header>
      <div
        className={cx({
          'h-[72px]': hasHeight,
        })}
      />
    </>
  )
}
