import cx from 'classnames'
import { Avatar } from 'components/ui/Avatar'
import { Image } from 'components/ui/Image'
import { Text } from 'components/ui/Text'
import { useEventListener } from 'hooks/useEventListener'
import { useState } from 'react'

interface Props {
  imgUrl: string
  avatar: string
  name: string
  isViewed?: boolean
  className?: string
  onClick?: () => void
}

export function CarouselImage(props: Props) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const {
    imgUrl,
    avatar,
    isViewed,
    name,
    className,
    onClick = () => null,
  } = props

  useEventListener('click', onClick, ref)

  return (
    <div
      className={cx(
        'w-[100px] relative rounded-2xl overflow-hidden border border-gray-500 cursor-pointer',
        className,
      )}
      ref={(ref) => setRef(ref)}
    >
      <Image aspectRatio={100 / 187} src={imgUrl} className="rounded-2xl" />
      <div
        className={cx(
          'absolute w-10 h-10 flex items-center justify-center left-2 border-2 top-2 rounded-full',
          {
            'border-primary': isViewed,
            'border-white': !isViewed,
          },
        )}
      >
        <Avatar imgUrl={avatar} size="sm" />
      </div>
      <div className="absolute inset-0 top-[65%] z-10 bg-gradient-to-t from-[#00000091] rounded-b-2xl">
        <Text
          className="absolute bottom-2 left-2 right-2 text-sm text-white"
          truncate="2"
          textColor="none"
        >
          {name}
        </Text>
      </div>
    </div>
  )
}
