import { useEffect, useRef } from 'react'

export function useEventListener(
  eventName: string,
  handler: (event: any) => void,
  element: HTMLDivElement | null,
) {
  const savedHandler = useRef<any>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener
      if (!isSupported) return
      // Create event listener that calls handler function stored in ref
      const eventListener = (event: any) => savedHandler.current(event)
      // Add event listener
      element.addEventListener(eventName, eventListener)
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element], // Re-run if eventName or element changes
  )
}
