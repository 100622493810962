import cx from 'classnames'
import { HTMLAttributes } from 'react'
import { WithChildren } from 'types/common'

interface Props extends WithChildren<HTMLAttributes<HTMLDivElement>> {}

export const BottomSheetContent = (props: WithChildren<Props>) => {
  const { children, className, ...rest } = props
  return (
    <div
      {...rest}
      className={cx('p-4 grow-0 shrink basis-[1%] overflow-auto', className)}
    >
      {children}
    </div>
  )
}
