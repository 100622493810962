import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import cx from 'classnames'
import { IS_IOS_LESS_THAN_13 } from 'constants/mobile'
import { BottomSheetContextProvider } from './context'
import { BottomSheetCloseButton } from './BottomSheetCloseButton'
import { BottomSheetBackButton } from './BottomSheetBackButton'
import { BottomSheetContent } from './BottomSheetContent'
import { BottomSheetTitle } from './BottomSheetTitle'
import { BottomSheetHeader } from './BottomSheetHeader'
import { BottomSheetFooter } from './BottomSheetFooter'

export interface BottomSheetProps {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export function BottomSheet(props: BottomSheetProps) {
  const { isOpen, children, onClose, className, ...rest } = props
  const [maxHeight, setMaxHeight] = useState(0)

  const animationProps = {
    enter: 'ease-out duration-300',
    enterFrom: 'translate-y-full',
    enterTo: 'translate-y-0',
    leave: 'ease-in duration-200',
    leaveFrom: 'translate-y-0',
    leaveTo: 'translate-y-full',
  }

  useEffect(() => {
    const VERTICAL_PADDING = 60

    function resizeCallback() {
      setMaxHeight(window.innerHeight - VERTICAL_PADDING)
    }
    resizeCallback()

    window.addEventListener('resize', resizeCallback)

    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  }, [])

  return (
    <BottomSheetContextProvider value={{ isOpen, onClose }}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => {}}
          initialFocus={undefined}
          open={IS_IOS_LESS_THAN_13 ? isOpen : undefined}
        >
          <div className="sm:relative sm:container sm:h-full sm:transform sm:translate-x-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0 bg-black bg-opacity-50"
                onClick={onClose}
              />
            </Transition.Child>
            <Transition.Child as={Fragment} {...animationProps}>
              <div
                {...rest}
                className={cx(
                  'fixed bottom-8 rounded-2xl overflow-hidden flex flex-col inset-x-4 bg-white',
                  className,
                )}
                style={{
                  maxHeight,
                }}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </BottomSheetContextProvider>
  )
}

export {
  BottomSheetCloseButton,
  BottomSheetBackButton,
  BottomSheetTitle,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetFooter,
}
