import { RowItem } from 'components/ui-v2/RowItem'
import { ImageWithFallback } from 'components/ui/Image'
import { Text } from 'components/ui/Text'
import { IconArrowRight } from 'components/icons/components/IconArrowRight'
import { useTranslations } from 'next-intl'
import { Confirm } from 'components/ui/Confim'
import { useDisclosure } from '@dwarvesf/react-hooks'
import { ROUTES } from 'constants/routes'
import { useAuthContext } from 'context/auth'
import { BackLink } from 'components/common/BackLink'
import { SyntheticEvent } from 'react'

interface Props {
  isPendingKYC: boolean
}

export function NewContractReminder({ isPendingKYC }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user } = useAuthContext()
  const t = useTranslations()

  const handlePress = (event: SyntheticEvent) => {
    if (isPendingKYC) {
      event.preventDefault()
      onOpen()
    }
  }

  return (
    <>
      <BackLink
        href={ROUTES.PROFILE_CONTRACT_DETAIL(user?.contract?.id as string)}
      >
        <RowItem
          onClick={handlePress}
          className="px-4 py-3 from-v2-secondary-200 to-v2-secondary-400 bg-gradient-to-r rounded-2xl"
          leftIcon={
            <ImageWithFallback
              width="48"
              height="48"
              src="/img/contract-hand-shake.png"
              fallback="/img/contract-hand-shake.png"
              className="max-w-none"
            />
          }
          rightIcon={<IconArrowRight className="text-xl text-white" />}
          subtitle={
            <Text fontSize="sm" v2TextColor="White" className="text-[13px]">
              {t('new_contract_reminder_prompt')}
            </Text>
          }
        />
      </BackLink>
      <Confirm
        onClose={onClose}
        isOpen={isOpen}
        confirmText={t('understood')}
        onConfirm={onClose}
        title={t('waiting_for_KYC_approval')}
        message={t('waiting_for_KYC_approval_message')}
      />
    </>
  )
}
