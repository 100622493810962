import React from 'react'
import cx from 'classnames'
import { useMergeRefs } from '@dwarvesf/react-hooks'
import { forwardRefWithAs } from 'utils/render'
import { IconUserCircle } from 'components/icons/components/IconUserCircle'
import { Box, BoxProps } from '../Box'
import { Image } from '../Image'
import { AspectRatio } from '../AspectRatio'

export interface AvatarProps extends BoxProps {
  className?: string
  imgUrl?: string
  alt?: string
  isSquare?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
  fallback?: string
}

function getSize(size: AvatarProps['size']) {
  if (size === '4xl') {
    return 'w-20 h-20'
  }

  if (size === '3xl') {
    return 'w-[72px] h-[72px]'
  }

  if (size === '2xl') {
    return 'w-16 h-16'
  }

  if (size === 'xl') {
    return 'w-14 h-14'
  }

  if (size === 'lg') {
    return 'w-12 h-12'
  }

  if (size === 'md') {
    return 'w-10 h-10'
  }

  if (size === 'sm') {
    return 'w-8 h-8'
  }

  if (size === 'xs') {
    return 'w-6 h-6'
  }
}

function getIconSize(size: AvatarProps['size']) {
  if (size === '3xl' || size === '4xl') {
    return 'text-3xl'
  }

  if (size === '2xl') {
    return 'text-2xl'
  }

  if (size === 'xl') {
    return 'text-xl'
  }

  if (size === 'lg') {
    return 'text-lg'
  }

  return 'text-md'
}

export const Avatar = forwardRefWithAs<'div', AvatarProps>((props, ref) => {
  const {
    children: childrenProps,
    className,
    imgUrl,
    alt,
    size = 'md',
    isSquare = false,
    fallback = '/img/fallback-avatar.png',
    ...rest
  } = props
  const internalRef = React.useRef<HTMLDivElement>(null)
  const mergedRef = useMergeRefs(internalRef, ref)

  return (
    <Box
      ref={mergedRef}
      className={cx('inline-block', getSize(size), className)}
      {...rest}
    >
      {imgUrl ? (
        <Image
          src={imgUrl}
          alt={alt}
          aspectRatio={1}
          className={cx({ 'rounded-lg': isSquare, 'rounded-full': !isSquare })}
          fallback={fallback}
        />
      ) : (
        <AspectRatio ratio={1}>
          <div
            className={cx(
              'rounded-full flex items-center justify-center bg-v2-gray-200',
              getIconSize(size),
            )}
          >
            <IconUserCircle className="text-white" />
          </div>
        </AspectRatio>
      )}
    </Box>
  )
})
