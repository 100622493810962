import { Card } from 'components/ui/Card'
import { Skeleton } from 'components/ui/Skeleton'

const NewFeedSkeleton = () => {
  return (
    <Card className="flex gap-3 pt-5">
      <div>
        <Skeleton className="w-10 h-10 rounded-full" />
      </div>
      <div className="w-full">
        <div className="w-full space-y-3">
          <Skeleton className="h-4 w-20 rounded" />
          <div className="space-y-1">
            <Skeleton className="h-4 w-full rounded" />
            <Skeleton className="h-4 w-3/4 rounded" />
          </div>
        </div>
        <div className="flex gap-3 mt-[18px]">
          <Skeleton className="h-5 w-20 rounded" />
          <Skeleton className="h-5 w-40 rounded" />
        </div>
      </div>
    </Card>
  )
}

export default NewFeedSkeleton
