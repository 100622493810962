import { IconComment } from 'components/icons/components/IconComment'
import { IconLike } from 'components/icons/components/IconLike'
import { IconLiked } from 'components/icons/components/IconLiked'
import { IconButton } from 'components/ui/IconButton'
import { Text } from 'components/ui/Text'
import cx from 'classnames'
import { RepliesData } from 'types/schema'
import { MouseEvent } from 'react'
import { Button } from 'components/ui/Button'
import { useTranslations } from 'next-intl'
import { useNewFeedListContext } from '../NewFeedList/context'

interface Props {
  id?: string
  isLike?: boolean
  comment?: RepliesData[]
  className?: string
  totalReaction?: number
}

export function LikeComment({
  id,
  isLike,
  className,
  comment = [],
  totalReaction,
}: Props) {
  const { onLikeFeed } = useNewFeedListContext()

  const t = useTranslations()

  return (
    <div className={cx('flex justify-between items-center gap-3', className)}>
      <div className="w-2/6 flex items-center">
        {isLike ? (
          <Button
            variant="link"
            isNoSpacing
            size="sm"
            className="!text-lightBlue-800 !font-normal ml-2"
            iconPosition="left"
            Icon={IconLiked}
            iconClassName="!text-lg !text-lightBlue-600"
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              onLikeFeed(id)
            }}
          >
            {totalReaction && totalReaction > 0
              ? totalReaction
              : t('feed_like')}
          </Button>
        ) : (
          <Button
            variant="link"
            isNoSpacing
            size="sm"
            className="!text-gray-800 !font-normal ml-2"
            iconPosition="left"
            Icon={IconLike}
            iconClassName="!text-lg !text-gray-700"
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              onLikeFeed(id)
            }}
          >
            {totalReaction && totalReaction > 0
              ? totalReaction
              : t('feed_like')}
          </Button>
        )}
      </div>

      <div className="flex w-full items-center gap-2">
        <div className="flex-none w-7">
          <IconButton
            label="Comment"
            Icon={IconComment}
            className="!text-lg w-fit"
            size="sm"
          />
        </div>

        <Text className="text-sm flex-1" textColor="800">
          {comment?.length > 0 ? comment.length : t('feed_comment_empty_label')}
        </Text>
      </div>
    </div>
  )
}
