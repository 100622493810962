import cx from 'classnames'
import AvatarV3 from 'components/ui-next/Avatar'
import { Text } from 'components/ui/Text'
import { FormatTimeAgo } from '../FormatTimeAgo'

export interface CommentProps {
  className?: string
  avatar: string
  name: string
  time: string
  message: string
}
export function Comment({
  className,
  avatar,
  name,
  time,
  message,
}: CommentProps) {
  return (
    <div className={cx('flex space-x-3', className)}>
      <AvatarV3 src={avatar} className="w-10 self-start" />
      <div className="w-full space-y-1.5 rounded-2xl bg-gray-300 px-2 py-3 !ml-2">
        <div className="flex items-center space-x-3">
          <Text textColor="1100" className="text-sm font-semibold" as="span">
            {name}
          </Text>
          <FormatTimeAgo time={time} className="text-xs" textColor="700" />
        </div>
        <Text className="text-sm break-all" textColor="1100">
          {message}
        </Text>
      </div>
    </div>
  )
}
