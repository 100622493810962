import { callAllHandlers } from '@dwarvesf/react-utils'
import cx from 'classnames'
import { IconX } from 'components/icons/components/IconX'
import { useTranslations } from 'next-intl'
import { IconButton, IconButtonProps } from '../IconButton'
import { useDrawerContext } from './context'

interface Props extends Omit<IconButtonProps, 'label' | 'Icon'> {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  scheme?: IconButtonProps['scheme']
}

export const DrawerCloseButton = ({
  className,
  disabled,
  onClick,
  Icon,
  scheme = 'primary',
  ...rest
}: Props) => {
  const { onClose } = useDrawerContext()
  const t = useTranslations()
  return (
    <IconButton
      disabled={disabled}
      onClick={callAllHandlers(onClose, onClick)}
      {...rest}
      className={cx(className, '!absolute left-2 center-y')}
      variant="link"
      scheme={scheme}
      Icon={Icon ?? IconX}
      label={t('close')}
    />
  )
}
