import { Layout } from 'components/common/Layout'
import { WithChildren } from 'types/common'
import { FeedHeader } from '../Header'

export function FeedLayout({ children }: WithChildren) {
  return (
    <Layout className="pb-[54px]" headerRender={<FeedHeader />}>
      {children}
    </Layout>
  )
}
