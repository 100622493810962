import { Transition } from '@headlessui/react'
import { userService } from 'api'
import { Repeated } from 'components/ui/Repeated'
import { Skeleton } from 'components/ui/Skeleton'
import { IS_IOS_LESS_THAN_13 } from 'constants/mobile'
import { useFetchStories } from 'hooks/feed/useFetchStories'
import { useMemo, useState } from 'react'
import { CarouselImage } from '../CarouselImage'
import { StoryList } from '../StoryList'

export function Story() {
  const { isFirstLoad, stories, setMapStoriesData } = useFetchStories()

  const [isOpen, setIsOpen] = useState(false)
  const [currentStory, setCurrentStory] = useState(0)
  const [isFirstShow, setIsFirstShow] = useState(false)

  const renderStoryList = useMemo(() => {
    return (
      <StoryList
        onCloseModal={() => {
          setIsOpen(false)
        }}
        stories={stories}
        currentStory={currentStory}
        setCurrentStory={setCurrentStory}
        isFirstShow={isFirstShow}
        setIsFirstShow={setIsFirstShow}
      />
    )
  }, [currentStory, isFirstShow, stories])

  if (isFirstLoad || stories?.length === 0) {
    return (
      <div className="flex space-x-2 bg-white px-2 py-3 overflow-hidden">
        <Repeated
          as={Skeleton}
          times={4}
          className="w-[100px] h-[185px] rounded-xl shrink-0"
        />
      </div>
    )
  }

  const onViewStory = async (item: any, index: number) => {
    setCurrentStory(index)
    setIsFirstShow(true)
    setIsOpen(true)
    if (item?.isViewed) {
      return
    }

    try {
      await userService.viewStory(item?.id)
      const newData = stories?.map((story) => ({
        ...story,
        isViewed:
          !story?.isViewed && story?.id === item?.id ? true : story?.isViewed,
      }))
      setMapStoriesData(newData)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {IS_IOS_LESS_THAN_13 ? (
        isOpen && (
          <div className="fixed inset-0 z-50">
            <div className="sm:container bg-black">{renderStoryList}</div>
          </div>
        )
      ) : (
        <Transition
          show={isOpen}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 z-50"
        >
          <div className="sm:container bg-black">{renderStoryList}</div>
        </Transition>
      )}
      <div className="flex snap-x space-x-2 bg-white px-2 py-3 overflow-auto scroll-smooth no-scrollbar">
        {(stories ?? [])?.map((item, index) => (
          <CarouselImage
            key={item?.id}
            avatar={item?.avatar}
            imgUrl={item?.images?.[0]?.fullUrl}
            name={item?.name}
            isViewed={item?.isViewed}
            className="scroll-ml-6 snap-start shrink-0"
            onClick={() => onViewStory(item, index)}
          />
        ))}
      </div>
    </>
  )
}
