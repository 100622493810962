import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useEventListener } from 'hooks/useEventListener'
import { MAX_CAROUSE_IMAGES } from 'constants/enum'
import { Avatar } from 'components/ui/Avatar'
import { IconClose } from 'components/icons/components/IconClose'
import { IconButton } from 'components/ui/IconButton'
import { CarouselTimer } from '../CarouselTimer'
import { FormatTimeAgo } from '../FormatTimeAgo'

interface CarouselImage {
  fullUrl: string
  alt?: string
}

export interface CarouselImageInfoProps {
  avatar: string
  name: string
  createdAt: string
  isActive?: boolean
  images: CarouselImage[]
  onNextStory?: () => void
  onPrevStory?: () => void
  onCloseModal?: () => void
}

const getStatus = (
  index: number,
  current: number,
  isPaused: boolean,
  isActive: boolean,
) => {
  if (!isActive) {
    return 'prepare'
  }

  if (current < 0) {
    return 'prepare'
  }

  if (index < current) {
    return 'end'
  }

  if (index > current) {
    return 'prepare'
  }

  if (isPaused) {
    return 'paused'
  }

  if (index === current) {
    return 'start'
  }

  return 'paused'
}

const CarouselImageInfo: React.FC<CarouselImageInfoProps> = ({
  images,
  avatar,
  name,
  createdAt,
  isActive = false,
  onNextStory = () => null,
  onPrevStory = () => null,
  onCloseModal = () => null,
}) => {
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<number>(0)

  const currentTime = useRef<number>(0)

  const onNext = useCallback(() => {
    if (currentImage + 1 < images?.length) {
      setCurrentImage(currentImage + 1)
    } else {
      onNextStory()
    }
  }, [currentImage, images, onNextStory])

  const onPrev = useCallback(() => {
    if (currentImage - 1 >= 0) {
      setCurrentImage(currentImage - 1)
    } else {
      onPrevStory()
    }
  }, [currentImage, onPrevStory])

  const onMouseDown = () => {
    setIsMouseDown(true)
  }

  const onMouseUp = (event: MouseEvent) => {
    if (isPaused) {
      setIsMouseDown(false)
    } else {
      const center: number = (event?.view?.innerWidth || 0) / 2

      if (event?.clientX < center) {
        onPrev()
      } else {
        onNext()
      }
    }
    setIsMouseDown(false)
    setIsPaused(false)
  }

  const onTouchEnd = () => {
    setIsMouseDown(false)
    setIsPaused(false)
  }

  useEventListener('mousedown', onMouseDown, ref)
  useEventListener('mouseup', onMouseUp, ref)
  useEventListener('touchstart', onMouseDown, ref)
  useEventListener('touchend', onTouchEnd, ref)

  useEffect(() => {
    if (!isActive) {
      setCurrentImage(0)
    }
  }, [isActive])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsPaused(isMouseDown)
    }, 300)

    return () => clearTimeout(timeOut)
  }, [isMouseDown])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isActive) {
        if (!isPaused) {
          currentTime.current = (currentTime?.current ?? 0) + 1
        }

        if (currentTime.current === MAX_CAROUSE_IMAGES) {
          onNext()
          currentTime.current = 0
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [isPaused, onNext, isActive])

  return (
    <div
      ref={(ref) => setRef(ref)}
      className="w-full h-screen relative overflow-hidden !transition-3d-0 bg-black"
    >
      <div className="w-full p-3 absolute inset-0 flex flex-col z-30">
        <div className="w-full flex gap-2">
          {(images?.length > 0 ? images : [])?.map(({ fullUrl }, index) => (
            <CarouselTimer
              status={getStatus(index, currentImage, isPaused, isActive)}
              key={fullUrl}
            />
          ))}
        </div>
        <div className="flex justify-between items-center mt-3">
          <div className="flex gap-3 items-center">
            <div>
              <div className="w-10 h-10 flex items-center justify-center border-2 rounded-full border-white ">
                <Avatar imgUrl={avatar} size="sm" />
              </div>
            </div>
            <span className="text-white text-[20px] font-semibold truncate max-w-[200px]">
              {name}
            </span>
            <FormatTimeAgo className="text-white mt-[3px]" time={createdAt} />
          </div>
          <IconButton
            Icon={IconClose}
            onClick={onCloseModal}
            label=""
            className="bg-transparent"
          />
        </div>
      </div>
      <img
        className="absolute inset-0 w-full h-full object-contain z-10"
        src={images?.[currentImage]?.fullUrl}
        alt={images?.[currentImage]?.alt}
      />
    </div>
  )
}

export default CarouselImageInfo
