import { useTranslations } from 'next-intl'
import { Text } from 'components/ui/Text'
import cx from 'classnames'
import { IconLongArrowLeft } from 'components/icons/components/IconLongArrowLeft'
import { IconX } from 'components/icons/components/IconX'
import { ReactNode } from 'react'

interface MenuItemProps {
  key: string | number
  label: string
  icon: React.ReactElement
  onClick?: (key?: string | number) => void
}

interface FloatMenuProductTypeProps {
  open: boolean
  selected?: string | number
  menu: MenuItemProps[]
  onClose: () => void
  onOpen: () => void
  onBack?: () => void | undefined
  menuClassName?: string
  closeButtonClassName?: string
  Icon?: ReactNode
}

export function FloatMenuProductType({
  open,
  menu,
  selected,
  onClose,
  onOpen,
  onBack,
  menuClassName,
  closeButtonClassName,
  Icon,
}: FloatMenuProductTypeProps) {
  const t = useTranslations()

  return (
    <div data-testid="FloatMenuProductType" className="relative z-50">
      <div className="fixed bottom-0 inset-x-0 transform translate-x-0 container">
        <input
          type="checkbox"
          className="invisible peer"
          checked={open}
          readOnly
        />
        <button
          type="button"
          aria-label="background button"
          className="w-screen h-screen bg-transparent fixed inset-0 hidden peer-checked:block"
          onClick={onClose}
        />
        <button
          type="button"
          aria-label="Mở menu loại sản phẩm"
          onClick={onOpen}
          className="w-[47px] h-[40px] rounded-l-full bg-primary fixed right-0 bottom-16 flex items-center justify-center overflow-hidden peer-checked:w-0 transition-all duration-200"
        >
          {Icon || (
            <img
              src="/img/float-icon-droppii.svg"
              alt="droppii float icon"
              loading="eager"
            />
          )}
        </button>

        <button
          type="button"
          aria-label={t('close')}
          onClick={onClose}
          className={cx(
            'text-white bg-primary w-10 h-10 rounded-full flex items-center justify-center fixed right-[30px] bottom-16 peer-checked:visible peer-checked:opacity-100 peer-checked:rotate-180 invisible opacity-0 transition-all duration-300 transform rotate-0',
            closeButtonClassName,
          )}
        >
          <IconX />
        </button>

        <ul
          className={cx(
            'fixed px-1 right-3 bottom-[112px] w-[81px] bg-white rounded-[40px] space-y-4 py-6 drop-shadow-lg overflow-hidden peer-checked:visible peer-checked:opacity-100 invisible opacity-0 transition-all duration-300 ease-in-out',
            menuClassName,
          )}
        >
          {typeof onBack === 'function' && (
            <li>
              <button
                type="button"
                className="w-full text-primary flex flex-col items-center gap-1 pb-3 border-b border-gray-400 px-1 py-2"
                onClick={onBack}
              >
                <IconLongArrowLeft />
                <Text className="text-xs font-semibold" textColor="none">
                  {t('order_status_all')}
                </Text>
              </button>
            </li>
          )}
          {(menu ?? [])?.map(({ key, label, icon, onClick }) => (
            <li key={key}>
              <button
                type="button"
                className={cx(
                  'w-full text-gray-800 flex flex-col items-center gap-1 px-1 py-2',
                  {
                    'text-gray-1100 rounded-md bg-gray-300':
                      String(key) === String(selected),
                  },
                )}
                onClick={() => onClick?.(key)}
              >
                {icon}
                <Text className="text-xs font-semibold" textColor="800">
                  {label}
                </Text>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
