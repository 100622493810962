import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import cx from 'classnames'
import { getInputAppearance } from 'utils/ui'
import { IconEye } from 'components/icons/components/IconEye'
import { IconEyeClose } from 'components/icons/components/IconEyeClose'
import { useTranslations } from 'next-intl'
import { IconCloseCircle } from 'components/icons/components/IconCloseCircle'
import { IconButton } from '../IconButton'
import { Text } from '../Text'

export type InputSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs'
export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> {
  fullWidth?: boolean
  filled?: boolean
  readOnly?: boolean
  value?: string
  invalid?: boolean
  hasBorder?: boolean
  showNumericKeyboard?: boolean
  size?: InputSize
  prefix?: string | ReactElement
  suffix?: ReactNode
  onClear?: () => void
  type?: 'text' | 'date' | 'time' | 'email' | 'number' | 'tel' | 'password'
  inputType?: 'number' | 'alphabet-number'
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      fullWidth = false,
      disabled = false,
      invalid = false,
      onFocus,
      onBlur,
      type,
      hasBorder = true,
      className,
      onClear,
      value,
      showNumericKeyboard,
      size = 'lg',
      prefix,
      suffix,
      onChange,
      inputType,
      ...rest
    } = props
    const t = useTranslations()
    const isPassword = type === 'password'
    const [internalType, setInternalType] = useState(type)
    const [inputValue, setInputValue] = useState(value)
    const [isFocusing, setIsFocusing] = useState(false)

    const hasClearButton = typeof onClear === 'function'
    const hasTwoActions = hasClearButton && isPassword

    useEffect(() => {
      setInputValue(value)
    }, [value])

    const onChangeInput = (e: any) => {
      const { value } = e.target

      if (inputType && value) {
        if (inputType === 'alphabet-number') {
          e.target.value = value.replace(
            // eslint-disable-next-line no-useless-escape
            /[”“’`₫~!@#$%^&*()_|+\-=?;:'",.<> •€¥\{\}\[\]\\\/]/gi,
            '',
          )
        }
        if (inputType === 'number') {
          e.target.value = value.replace(/[^0-9]/gi, '')
        }
      }
      setInputValue(e.target.value)
      onChange?.(e)
    }

    return (
      <div
        className={cx('relative inline-flex', {
          'w-full': fullWidth,
        })}
      >
        {prefix ? (
          <div className="border-r border-v2-gray-50 absolute left-0 w-20 top-3 bottom-3 flex items-center justify-center">
            <Text as="span" fontSize="md" v2TextColor="Gray-Dark">
              {prefix}
            </Text>
          </div>
        ) : null}
        <input
          pattern={showNumericKeyboard ? '[0-9]*' : undefined}
          inputMode={showNumericKeyboard ? 'numeric' : undefined}
          type={internalType}
          className={cx(
            'border rounded-lg py-2 w-full block text-sm',
            { 'pl-3': !prefix, 'pl-20': prefix },
            { 'pr-3': !suffix, 'pr-12': suffix },
            {
              'pr-3': !hasClearButton && !isPassword,
              'pr-11': hasClearButton || isPassword,
              'pr-23': hasTwoActions,
            },
            {
              'h-[56px]': size === 'xl',
              'h-[48px]': size === 'lg',
              'h-[40px]': size === 'md',
              'h-[32px]': size === 'sm',
              'h-[28px]': size === 'xs',
            },
            getInputAppearance({
              disabled,
              invalid,
              hasBorder,
            }),
            className,
          )}
          value={inputValue}
          onFocus={(event) => {
            setIsFocusing(true)
            if (typeof onFocus === 'function') {
              onFocus(event)
            }
          }}
          onBlur={(event) => {
            setIsFocusing(false)
            if (typeof onBlur === 'function') {
              onBlur(event)
            }
          }}
          onChange={onChangeInput}
          {...rest}
          ref={ref}
          disabled={disabled}
        />

        {suffix ? (
          <div className="absolute right-0 w-12 top-3 bottom-3 flex items-center justify-center">
            {suffix}
          </div>
        ) : null}

        {typeof onClear === 'function' && value ? (
          <IconButton
            className={cx(
              '!absolute center-y text-v2-gray-200 transition-none',
              {
                'right-1': !hasTwoActions,
                'right-10': hasTwoActions,
                'opacity-0 pointer-events-none !cursor-default': !isFocusing,
              },
            )}
            type="button"
            onClick={onClear}
            Icon={IconCloseCircle}
            size="xs"
            disabled={disabled}
            label={t('clear_input')}
            variant="link"
            scheme="light"
            onFocus={() => setIsFocusing(true)}
            onBlur={() => setIsFocusing(false)}
          />
        ) : null}
        {isPassword ? (
          <IconButton
            className="!absolute center-y right-1"
            type="button"
            onClick={() =>
              setInternalType(internalType === 'password' ? 'text' : 'password')
            }
            Icon={internalType === 'password' ? IconEye : IconEyeClose}
            label={internalType === 'password' ? t('show') : t('hide')}
            variant="link"
            scheme="light"
          />
        ) : null}
      </div>
    )
  },
)

export default Input
