import { Empty } from 'components/ui/Empty'
import { ImageWithFallback } from 'components/ui/Image'
import { LoadMore } from 'components/ui/LoadMore'
import { formatImage } from 'utils/string'
import { Repeated } from 'components/ui/Repeated'
import { useTranslations } from 'next-intl'
import { useNewFeedListContext } from './context'
import { NewFeedInfo } from '../NewFeedInfo'
import { OrderCard } from '../OrderCard'
import NewFeedSkeleton from './NewFeedSkeleton'

const NewFeedList = () => {
  const {
    newFeeds,
    isEmpty,
    sentryRef,
    hasNextPage,
    isFirstLoading,
    loading,
    isLoadingMore,
    setNewFeed,
    setIsOpen,
  } = useNewFeedListContext()

  const t = useTranslations()

  if (isFirstLoading && !isLoadingMore) {
    return (
      <div className="space-y-2 bg-white p-3">
        <Repeated as={NewFeedSkeleton} times={5} />
      </div>
    )
  }

  if (isEmpty) {
    return (
      <div className="pt-20 h-full mx-auto">
        <Empty
          message={t('new_feed_empty')}
          illustration={
            <ImageWithFallback
              width="100"
              height="100"
              fallback="img/package-done.png"
              src="img/package-done.png"
            />
          }
        />
      </div>
    )
  }

  return (
    <div className="space-y-2">
      {newFeeds?.map((item) => {
        return (
          <OrderCard
            id={item?.id}
            key={item?.id}
            totalReaction={item?.totalReaction}
            message={item?.content}
            name={item?.fullName}
            avatar={formatImage(item?.avatar)}
            isLike={item?.isLike}
            comment={item?.replies}
            time={item?.createdAt}
            className="pt-5 pb-3"
            onClick={() => {
              setIsOpen(true)
              setNewFeed(item)
            }}
          />
        )
      })}
      <NewFeedInfo />
      {(loading || hasNextPage) && <LoadMore ref={sentryRef} />}
    </div>
  )
}

export default NewFeedList
