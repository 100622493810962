import { useAsyncEffect } from '@dwarvesf/react-hooks'
import { userService } from 'api'
import { IconSpinner } from 'components/icons/components/IconSpinner'
import { Button } from 'components/ui/Button'
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/Drawer'
import { toast } from 'components/ui/Toast'
import { POLICY_TYPE } from 'constants/enum'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

interface AcceptPolicyPanelProps {
  isOpen: boolean
  version: number
  type: number
}

export function AcceptPolicyPanel({
  isOpen,
  type = 0,
  version = 0,
}: AcceptPolicyPanelProps) {
  const [Component, setComponent] = useState<React.ComponentType<{}>>()
  const [isOpenPanel, setIsOpenPanel] = useState(false)

  const t = useTranslations()

  useEffect(() => {
    setIsOpenPanel(isOpen)
  }, [isOpen])

  useAsyncEffect(async () => {
    if (isOpen && !Component) {
      try {
        const response = await dynamic(
          import(
            `components/profile/policies/en-US/${POLICY_TYPE[type]}/v${version}`
          ),
        )
        setTimeout(() => {
          setComponent(response)
        }, 500)
      } catch (error) {
        console.error(error)
      }
    }
  }, [isOpen, Component])

  const onAcceptPolicy = async () => {
    try {
      await userService.acceptPolicy({ type, version })
      setIsOpenPanel(false)
    } catch (error) {
      console.error(error)
      toast.error({
        message: t('unknown_error_message'),
      })
    }
  }

  return (
    <Drawer
      anchor="bottom"
      isOpen={isOpenPanel}
      onClose={() => setIsOpenPanel(false)}
    >
      <DrawerHeader className="!px-4">
        <DrawerTitle>{t('change_policies_title')}</DrawerTitle>
      </DrawerHeader>
      <DrawerContent className="absolute inset-x-0 top-[72px] bottom-0 flex items-center justify-center">
        <div className="absolute inset-0 bottom-[72px] overflow-y-auto p-3 content-entry">
          {!Component ? (
            <IconSpinner className="w-6 h-6 text-primary absolute center-x center-y" />
          ) : (
            <Component />
          )}
        </div>
        <DrawerFooter className="absolute bottom-0 px-4 p-4 border-t border-gray-400">
          <Button fullWidth onClick={onAcceptPolicy}>
            {t('agree_and_continue')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
