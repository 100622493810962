import cx from 'classnames'
import { Dialog } from '@headlessui/react'
import { WithChildren } from 'types/common'

export const DrawerTitle = ({
  className,
  ...props
}: WithChildren<{ className?: string }>) => {
  return (
    <Dialog.Title
      as="h3"
      className={cx(
        className,
        'text-gray-1100 leading-snug font-semibold text-xl w-full',
      )}
      {...props}
    />
  )
}
