import { callAllHandlers } from '@dwarvesf/react-utils'
import cx from 'classnames'
import { IconX } from 'components/icons/components/IconX'
import { useTranslations } from 'use-intl'
import { IconButton, IconButtonProps } from '../IconButton'
import { useBottomSheetContext } from './context'

interface Props extends Omit<IconButtonProps, 'label' | 'Icon'> {}

export const BottomSheetCloseButton = (props: Props) => {
  const { className, disabled, onClick, ...rest } = props
  const { onClose } = useBottomSheetContext()
  const t = useTranslations()

  return (
    <IconButton
      disabled={disabled}
      onClick={callAllHandlers(onClose, onClick)}
      {...rest}
      className={cx(
        className,
        '!text-2xl !absolute right-4 center-y text-v2-gray-400',
      )}
      variant="link"
      scheme="light"
      size="sm"
      Icon={IconX}
      label={t('close')}
    />
  )
}
