import { Card } from 'components/ui/Card'
import { RepliesData } from 'types/schema'
import { forwardRefWithAs } from 'utils/render'
import { LikeComment } from '../LikeComment'
import { OrderInfo } from '../OrderInfo'

interface Props {
  id?: string
  avatar: string
  name: string
  time: string
  comment?: RepliesData[]
  message: string
  className?: string
  isLike?: boolean
  totalReaction?: number
}

export const OrderCard = forwardRefWithAs<'div', Props>((props, ref) => {
  const {
    id,
    avatar,
    message,
    name,
    time,
    className,
    isLike = false,
    comment = [],
    totalReaction,
    ...rest
  } = props

  return (
    <Card className={className} {...rest} ref={ref}>
      <OrderInfo avatar={avatar} message={message} name={name} time={time} />
      <div className="w-full flex items-center mt-2">
        <div className="flex-none w-10" />
        <LikeComment
          id={id}
          isLike={isLike}
          comment={comment}
          totalReaction={totalReaction}
          className="w-full ml-1"
        />
      </div>
    </Card>
  )
})
