import { RowItem } from 'components/ui-v2/RowItem'
import { ImageWithFallback } from 'components/ui/Image'
import { Text } from 'components/ui/Text'
import { IconArrowRight } from 'components/icons/components/IconArrowRight'
import { useTranslations } from 'next-intl'
import { useDisclosure } from '@dwarvesf/react-hooks'
import { Confirm } from 'components/ui/Confim'
import { useRouter } from 'next/router'
import { ROUTES } from 'constants/routes'

export function KYCReminder() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { push } = useRouter()
  const t = useTranslations()

  const handleNavigateToKYC = () => {
    push(ROUTES.PROFILE_KYC)
  }

  return (
    <>
      <RowItem
        onClick={onOpen}
        className="px-4 py-3 from-v2-success-400 to-v2-success-600 bg-gradient-to-r rounded-2xl"
        leftIcon={
          <ImageWithFallback
            width="48"
            height="48"
            src="/img/KYC-reminder.png"
            fallback="/img/KYC-reminder.png"
            className="max-w-none"
          />
        }
        rightIcon={<IconArrowRight className="text-xl text-white" />}
        subtitle={
          <Text fontSize="sm" v2TextColor="White" className="text-[13px]">
            {t('KYC_reminder_prompt')}
          </Text>
        }
      />
      <Confirm
        isOpen={isOpen}
        onClose={onClose}
        title={t('unverified_info')}
        message={t('verify_personal_info_to_sign_contract')}
        confirmText={t('verify')}
        cancelText={t('later')}
        onConfirm={handleNavigateToKYC}
        onCancel={onClose}
      />
    </>
  )
}
