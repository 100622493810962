import { CarouselImageInfoProps } from 'components/feed/CarouselImageInfo'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import CarouselImageInfo from '../CarouselImageInfo/CarouselImageInfo'

export interface StoryProps extends CarouselImageInfoProps {
  id: string
  isViewed: boolean
}

interface StoryListProps {
  stories?: StoryProps[]
  currentStory: number
  isFirstShow: boolean
  onCloseModal?: () => void
  setCurrentStory: Dispatch<SetStateAction<number>>
  setIsFirstShow: Dispatch<SetStateAction<boolean>>
}

export const StoryList: React.FC<StoryListProps> = ({
  stories = [],
  currentStory,
  isFirstShow,
  onCloseModal = () => null,
  setCurrentStory,
  setIsFirstShow,
}) => {
  const [swiper, setSwiper] = useState<SwiperType>()

  const slideTo = useCallback(
    (index: number) => {
      if (isFirstShow) {
        swiper?.slideTo(index, 0)
        setIsFirstShow(false)
      } else {
        swiper?.slideTo(index)
      }
    },
    [swiper, isFirstShow, setIsFirstShow],
  )

  useEffect(() => {
    slideTo(currentStory)
  }, [currentStory, slideTo])

  const onNextStory = useCallback(() => {
    if (currentStory + 1 >= stories?.length) {
      onCloseModal()
      return
    }
    setCurrentStory(currentStory + 1)
  }, [currentStory, setCurrentStory, onCloseModal, stories])

  const onPrevStory = useCallback(() => {
    if (currentStory - 1 < 0) {
      onCloseModal()
      return
    }
    setCurrentStory(currentStory - 1)
  }, [currentStory, setCurrentStory, onCloseModal])

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      keyboard={{
        enabled: true,
      }}
      touchStartPreventDefault={false}
      onSwiper={(sw: SwiperType) => {
        setSwiper(sw)
      }}
      onSlideChange={(e) => {
        setCurrentStory(e?.realIndex)
      }}
      onProgress={(_, process) => {
        if (process < -0.02 || process > 1.02) {
          onCloseModal()
        }
      }}
    >
      {stories?.map((item, index) => (
        <SwiperSlide
          key={item?.id}
          className="flex justify-center w-fit swiper-wrapper transition-3d-0"
        >
          <CarouselImageInfo
            {...item}
            key={item?.id}
            isActive={index === currentStory}
            onNextStory={onNextStory}
            onPrevStory={onPrevStory}
            onCloseModal={onCloseModal}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
