import { productService } from 'api'
import { useFetchWithCache } from 'hooks/useFetchWithCache'

export const SWR_KEYS_GET_PRODUCT_META_DATA = 'GET_PRODUCT_META_DATA'

export const useFetchProductMetaData = () => {
  const { data, isFirstLoading, error } = useFetchWithCache(
    [SWR_KEYS_GET_PRODUCT_META_DATA],
    () => productService.getProductMetaData(),
    {
      dedupingInterval: 5 * 60 * 1000,
    },
  )

  return {
    meta: data?.data,
    isFirstLoading,
    error,
  }
}

export const useFetchHolidayMetadata = () => {
  const { meta, ...rest } = useFetchProductMetaData()

  return {
    holidayInfo: meta?.holidayInfo,
    isHaveHoliday: meta?.isHaveHoliday,
    ...rest,
  }
}
