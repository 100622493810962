import cx from 'classnames'
import { HTMLAttributes } from 'react'
import { WithChildren } from 'types/common'

interface Props extends HTMLAttributes<HTMLDivElement> {
  hasPadding?: boolean
}

export const BottomSheetFooter = (props: WithChildren<Props>) => {
  const { className, hasPadding = true, children, ...rest } = props
  return (
    <footer
      {...rest}
      className={cx(
        'border-t border-v2-gray-25',
        { 'mx-4 py-4': hasPadding },
        className,
      )}
    >
      {children}
    </footer>
  )
}
