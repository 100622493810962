import useSWR from 'swr'
import { PAGE_SIZE_DEFAULT } from 'constants/paging'
import { useState, useCallback, useEffect } from 'react'
import { GetNewFeedResponseData } from 'types/schema'
import { userService } from 'api'

export const SWR_KEYS_GET_NEW_FEEDS = 'GET_NEW_FEEDS'

export const useFetchFeeds = () => {
  const [newFeeds, setNewFeeds] = useState<GetNewFeedResponseData[]>()
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const { data, error } = useSWR(
    [SWR_KEYS_GET_NEW_FEEDS, currentPage],
    (_, currentPage) =>
      userService.getNewFeeds({
        Page: currentPage as number,
        PageSize: PAGE_SIZE_DEFAULT,
        Includes: [],
        Filters: [],
        Sorts: ['createdAt'],
      }),
    {
      revalidateOnFocus: false,
    },
  )

  const isFirstLoading = !newFeeds && !error
  const loading = !data && !error

  const handleLoadMore = useCallback(() => {
    if (
      !isFirstLoading &&
      !loading &&
      data?.pageable?.pageNumber === currentPage
    ) {
      setCurrentPage(currentPage + 1)
      setIsLoadingMore(true)
    }
  }, [isFirstLoading, loading, currentPage, data])

  const hasNextPage =
    (data?.pageable?.pageNumber ?? 0) < (data?.pageable?.totalPages ?? 0)

  const isEmpty = data?.pageable?.totalElements === 0

  useEffect(() => {
    if (data && data.data) {
      if (currentPage === 1) {
        window.scrollTo(0, 0)
        setNewFeeds(data.data)
      } else {
        setNewFeeds((newFeeds) => [...(newFeeds ?? []), ...data.data])
        setIsLoadingMore(false)
      }
    }
  }, [data, currentPage])

  return {
    newFeeds,
    isFirstLoading,
    loading,
    isEmpty,
    hasNextPage,
    currentPage,
    error,
    isLoadingMore,
    handleLoadMore,
    setNewFeeds,
  }
}
