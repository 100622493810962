import { userService } from 'api'
import { useFetchWithCache } from 'hooks/useFetchWithCache'

export const SWR_KEYS_GET_POLICIES = 'GET_POLICIES'

export const useFetchUserAgreement = () => {
  const { data, ...rest } = useFetchWithCache([SWR_KEYS_GET_POLICIES], () =>
    userService.getUserAgreement(),
  )

  return {
    data: data?.data,
    ...rest,
  }
}
