import { Text } from 'components/ui/Text'
import cx from 'classnames'
import AvatarV3 from 'components/ui-next/Avatar'
import { FormatTimeAgo } from '../FormatTimeAgo'

export interface OrderInfoProps {
  avatar: string
  name: string
  time: string
  message: string
  className?: string
}
export function OrderInfo(props: OrderInfoProps) {
  const { avatar, message, name, time, className } = props
  return (
    <div className={cx('flex space-x-3', className)}>
      <AvatarV3 src={avatar} className="w-10 self-start" />
      <div className="w-full space-y-1.5">
        <div className="flex items-center space-x-3">
          <Text textColor="1100" className="text-sm font-semibold" as="span">
            {name}
          </Text>
          <FormatTimeAgo time={time} className="text-xs" textColor="700" />
        </div>
        <Text className="text-sm" textColor="1100">
          {/* FIXME: need to handle script injection */}
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Text>
      </div>
    </div>
  )
}
