import { ImageWithFallback } from 'components/ui/Image'
import { Text } from 'components/ui/Text'
import { IconButton } from 'components/ui/IconButton'
import { IconArrowRight } from 'components/icons/components/IconArrowRight'
import { useTranslations } from 'next-intl'
import { ROUTES } from 'constants/routes'
import { BackLink } from 'components/common/BackLink'

export function TetHomeAlert() {
  const t = useTranslations()

  return (
    <div
      className="relative w-full h-[60px] flex items-center rounded-2xl"
      style={{
        background: 'linear-gradient(92.63deg, #9B76FF 0%, #6C3CF4 100%)',
      }}
    >
      <div className="absolute left-1 bottom-0 w-[68px] h-[68px]">
        <ImageWithFallback
          src="/img/fast-delivery.png"
          fallback="/img/fast-delivery.png"
          className="w-full h-full"
        />
      </div>
      <div className="flex grow items-center justify-between ml-[76px] mr-2">
        <Text v2TextColor="White" className="font-semibold">
          {t('tet_holiday_home_notice')}
        </Text>
        <BackLink href={ROUTES.DELIVERY_PROVIDER_TET_SCHEDULE}>
          <IconButton
            label="Go"
            variant="transparent"
            scheme="white"
            Icon={IconArrowRight}
          />
        </BackLink>
      </div>
    </div>
  )
}
