import { useEffect, useState } from 'react'
import { userService } from 'api'
import { useFetchWithCache } from 'hooks/useFetchWithCache'

export const SWR_KEYS_GET_STORIES = 'SWR_KEYS_GET_STORIES'

export interface MapStoryData {
  name: string
  id: string
  isViewed: boolean
  images: {
    alt?: string | undefined
    fullUrl: string
  }[]
  avatar: string
  createdAt: string
}

export const useFetchStories = () => {
  const { data, mutate, loading, isFirstLoading } = useFetchWithCache(
    [SWR_KEYS_GET_STORIES],
    () => userService.getStories(),
    {
      revalidateOnFocus: false,
    },
  )

  const [mapStoriesData, setMapStoriesData] = useState<MapStoryData[]>()

  const isFirstLoad: boolean = loading || isFirstLoading

  useEffect(() => {
    const mapData = (data?.data?.userStories ?? [])?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
        isViewed: item?.isViewed,
        images: item?.images?.['700x1260'],
        avatar: item?.avatarUrl || '',
        createdAt: item?.createdAt || '',
      }
    })
    setMapStoriesData(mapData)
  }, [data])

  return {
    isFirstLoad,
    stories: mapStoriesData,
    mutate,
    data,
    setMapStoriesData,
  }
}
