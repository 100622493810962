import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

export type CarouselTimerStatus = 'start' | 'paused' | 'end' | 'prepare'

export interface CarouselTimerProps extends HTMLAttributes<HTMLDivElement> {
  status: CarouselTimerStatus
}

function getCarouselTimerStyle(status: CarouselTimerStatus) {
  const style = {
    start: 'animate-[timer-start_10s_linear]',
    paused: 'animate-[timer-start_10s_linear] animate-paused',
    end: 'w-full',
    prepare: 'w-0',
  }

  return style?.[status]
}

export const CarouselTimer: React.FC<CarouselTimerProps> = ({
  status = 'start',
}) => {
  const classNames = [
    'bg-gray-100 h-[3px] absolute z-10 top-0 left-0 rounded-lg',
    getCarouselTimerStyle(status),
  ]

  return (
    <div className="w-full rounded-lg h-[3px] relative">
      <div className="w-full  absolute h-[3px] top-0 left-0 bg-gray-100 opacity-30 rounded-lg" />
      <div className={cx(classNames)} />
    </div>
  )
}
