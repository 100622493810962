import { IconSend } from 'components/icons/components/IconSend'
import { IconButton } from 'components/ui/IconButton'
import { Input } from 'components/ui/Input'
import {
  BottomSheet,
  BottomSheetCloseButton,
  BottomSheetContent,
  BottomSheetFooter,
  BottomSheetHeader,
} from 'components/ui/BottomSheet'
import { Text } from 'components/ui/Text'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { formatImage } from 'utils/string'
import { useTranslations } from 'next-intl'
import { Comment } from '../Comment'
import { LikeComment } from '../LikeComment'
import { useNewFeedListContext } from '../NewFeedList/context'
import { OrderInfo } from '../OrderInfo'

export function NewFeedInfo() {
  const { selectedFeed, isOpen, setIsOpen, onCommentFeed } =
    useNewFeedListContext()

  const [value, setValue] = useState('')

  const t = useTranslations()

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onComment = () => {
    if (selectedFeed && value !== '') {
      onCommentFeed(selectedFeed.id, value)
      setValue('')
    }
  }

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onComment()
    }
  }

  return (
    <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <BottomSheetHeader>
        {selectedFeed && (
          <OrderInfo
            avatar={formatImage(selectedFeed?.avatar)}
            message={selectedFeed?.content}
            name={selectedFeed?.fullName}
            time={selectedFeed?.createdAt}
          />
        )}
        <BottomSheetCloseButton className="!top-5 !right-2 !text-gray-700" />
      </BottomSheetHeader>

      <BottomSheetContent className="p-0">
        <LikeComment
          className="py-1 px-4 border-b border-gray-400 flex-none"
          isLike={selectedFeed?.isLike}
          comment={selectedFeed?.replies}
          totalReaction={selectedFeed?.totalReaction}
          id={selectedFeed?.id}
        />

        {selectedFeed?.replies && selectedFeed?.replies?.length > 0 ? (
          <div className="p-4 space-y-4">
            {selectedFeed?.replies?.map((item) => (
              <Comment
                key={item?.id}
                avatar={formatImage(item?.avatar)}
                message={item?.content}
                name={item?.fullName}
                time={item?.createdAt}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center py-10">
            <Text textColor="800" className="max-w-xs mx-auto text-center px-6">
              {t('feed_comment_empty_message')}
            </Text>
          </div>
        )}
      </BottomSheetContent>
      <BottomSheetFooter className="flex items-center">
        <div className="w-full h-fit relative">
          <Input
            className="rounded-full !h-10 !bg-gray-300 pr-11"
            fullWidth
            placeholder={t('feed_comment_placeholder')}
            onChange={onChangeInput}
            onKeyUp={onKeyUp}
            value={value}
            autoFocus
          />
          <div className="w-fit h-fit absolute top-[50%] translate-y-[-50%] right-1">
            <IconButton
              label=""
              Icon={IconSend}
              className="w-[35px] h-[35px] !bg-gray-300"
              onClick={onComment}
            />
          </div>
        </div>
      </BottomSheetFooter>
    </BottomSheet>
  )
}
