import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

interface Props extends Omit<LinkProps, 'href'> {
  href: string
  extraQuery?: Record<string, any>
  children: React.ReactNode
  className?: string
}

export const BackLink = (props: Props) => {
  const { href, extraQuery, className, ...rest } = props
  const { asPath } = useRouter()

  return (
    <Link
      href={{ pathname: href, query: { from: asPath, ...extraQuery } }}
      className={className}
      {...rest}
    />
  )
}
