import { forwardRefWithAs } from 'utils/render'
import cx from 'classnames'
import { HTMLAttributes } from 'react'
import { WithChildren } from 'types/common'
import { Box } from '../Box'

interface Props extends WithChildren<HTMLAttributes<HTMLDivElement>> {}

export const BottomSheetHeader = forwardRefWithAs<'header', Props>(
  (props, ref) => {
    const { className, children, as = 'header', ...rest } = props
    return (
      <Box
        ref={ref}
        as={as}
        {...rest}
        className={cx(
          'bg-white p-4 grow-0 shrink basis-[1%] relative shadow-[inset_0px_-1px_0px_#E5EBED]',
          className,
        )}
      >
        {children}
      </Box>
    )
  },
)
