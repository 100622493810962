import cx from 'classnames'
import { HTMLAttributes } from 'react'
import { WithChildren } from 'types/common'

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const DrawerFooter = (props: WithChildren<Props>) => {
  const { className, children, ...rest } = props
  return (
    <>
      <div className="h-[72px]" />
      <footer
        {...rest}
        className={cx(
          'bg-white z-20 absolute bottom-0 inset-x-0 h-[72px]',
          className,
        )}
      >
        {children}
      </footer>
    </>
  )
}
