// @ts-ignore no type definition for buildFormatter
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import TimeAgo from 'react-timeago'
import { Text, TextProps } from 'components/ui/Text'
import { useTranslations } from 'next-intl'

interface FormatTimeAgoProps extends TextProps {
  time: string
}

export function FormatTimeAgo({ time, ...props }: FormatTimeAgoProps) {
  const t = useTranslations()

  const languages = {
    day: t.rich('format_day_ago', {
      amount: '1',
      suffix: '',
    }),
    days: t.rich('format_day_ago', {
      amount: '%d',
      suffix: 's',
    }),
    hour: t.rich('format_hour_ago', {
      amount: '1',
      suffix: '',
    }),
    hours: t.rich('format_hour_ago', {
      amount: '%d',
      suffix: 's',
    }),
    minute: t.rich('format_minute_ago', {
      amount: '1',
      suffix: '',
    }),
    minutes: t.rich('format_minute_ago', {
      amount: '%d',
      suffix: 's',
    }),
    month: t.rich('format_month_ago', {
      amount: '1',
      suffix: '',
    }),
    months: t.rich('format_month_ago', {
      amount: '%d',
      suffix: 's',
    }),
    week: t.rich('format_week_ago', {
      amount: '1',
      suffix: '',
    }),
    weeks: t.rich('format_week_ago', {
      amount: '%d',
      suffix: 's',
    }),
    prefixAgo: '',
    prefixFromNow: null,
    seconds: t.rich('format_minute_ago', {
      amount: '1',
      suffix: '',
    }),
    suffixAgo: null,
    suffixFromNow: '',
    wordSeparator: ' ',
    year: t.rich('format_year_ago', {
      amount: '1',
      suffix: '',
    }),
    years: t.rich('format_year_ago', {
      amount: '%d',
      suffix: 's',
    }),
  }

  const formatter = buildFormatter(languages)

  return (
    <Text {...props}>
      {
        // https://stackoverflow.com/questions/71791347/npm-package-cannot-be-used-as-a-jsx-component-type-errors
        // @ts-ignore
        <TimeAgo date={time} formatter={formatter} maxPeriod={1} />
      }
    </Text>
  )
}
